<template>
    <div>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Detail Pesan Masuk</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div v-if="!isLoading" class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <label for="">Pengirim</label>
                            <input type="text" class="form-control" v-model="incoming.from" readonly>
                            <br>
                            <label for="">Tujuan</label>
                            <input type="text" class="form-control" :value="device.name + ' (' + device.status + ')'" readonly>
                            <br>
                            <label for="">Pesan</label>
                            <textarea class="form-control" cols="30" rows="10" v-model="incoming.text" readonly />
                            <br>
                            <label for="">Diterima Pada</label>
                            <input type="text" class="form-control" :value="convertDateToLocal(incoming.created_at)" readonly>
                            <br>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
      data() {
        return {
            id: '',
            incoming: {},
            device: {},
            history: [],
            isLoading: true,
        };
      },
      mounted() {
        this.loadData()
        this.id = this.$route.query.id
      },
      methods: {
        convertDateToLocal(date){
                return moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss")
        },
        async loadData(){
            try{
                let id = this.$route.query.id
                let detail = await this.$store.dispatch("incoming_detail", { id: id })
                this.incoming = detail.data.data.incoming
                this.device = this.incoming.device
                this.isLoading = false
            }catch(err){
                this.$toast.error(err.response.data.message)
                this.$router.push("/incoming")
            }
        },
        addVariable() {
            this.form.variable.push({id: this.form.variable.length + 1, value: ""});
        },
        deleteVariable() {
            this.form.variable.splice(-1)
        },
        preselectedGroup(){
            for (let i = 0; i < this.g.rows.length; i++) {
                let index = this.form.joined_group.indexOf(this.g.rows[i].id)
                if(index != -1){
                    this.$set(this.g.rows[i], 'vgtSelected', true);
                }
            }
        },
        setPayloadGroup(){
            let payload = {
                contact_id: this.$route.query.id,
                add: [],
                remove: []
            }

            let selected = this.$refs['group-table'].selectedRows
            let old = this.form.joined_group

            //ADD
            for(let i = 0;i < selected.length;i++){
                if(old.indexOf(selected[i].id) == -1){
                    payload.add.push(selected[i].id)
                }
            }

            //REMOVE
            for(let i = 0;i < old.length;i++){
                let status = false
                for(let j = 0;j < selected.length;j++){
                    if(old[i] == selected[j].id) status = true
                }

                if(status == false){
                    payload.remove.push(old[i])
                }
            }
            
            return payload
        },
        async updateContact(){
            try{
                let response = await this.$store.dispatch('contact_update', this.form)
                this.$toast.success(response.data.message);
            }catch(err){
                this.$toast.error(err.response.data.message);
            }
        },
        async saveGroup(){
            let payload = await this.setPayloadGroup()

            try{
                let response = await this.$store.dispatch('group_update_by_contact', payload)
                this.$toast.success(response.data.message);
                this.loadData()
            }catch(err){
                this.$toast.error(err.response.data.message);
            }
        }
      },
    };
</script>